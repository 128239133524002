<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <Link rel="stylesheet" href="https://use.typekit.net/znl1pjo.css" />
    </Head>
    <Body>
      <div class="layout layout--campaign">
        <TheHeader />
        <TheMobileMenu />
        <canvas class="mobile-menu-canvas" />
        <suspense>
          <slot />
        </suspense>
        <TheFooter class="layout__footer" />
      </div>
    </Body>
  </Html>
</template>

<script setup lang="ts">
import TheHeader from '@/components/layout/TheHeader.vue';
import TheMobileMenu from '@/components/layout/TheMobileMenu.vue';
import TheFooter from '@/components/layout/TheFooter.vue';

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});
</script>

<style lang="scss" scoped>
.layout {
  &__footer {
    position: relative;
  }
}

.mobile-menu-canvas {
  display: block;
  position: fixed;
  pointer-events: none;
  inset-block: 0;
  inset-inline: 0;
  inline-size: 100%;
  block-size: 100%;
  z-index: map-get($z-index, 'mobile-menu-canvas');
}
</style>
